import { Box, Typography } from '@mui/material';
import { ActionList, Badge, Button, IndexTable, Popover } from '@shopify/polaris';
import { useCallback, useImperativeHandle, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  deleteCourierFromLocation,
  deleteUserLocation,
  useGetLocationMapping,
} from 'src/api/mapping_api';
import {
  deleteConfirm,
  disconnectConfirm,
  exportConfirm,
  importDialogCourier,
  trackingConfirm,
} from 'src/components/dialog/confirmation';
import Image from 'src/components/image/image';
import Table from 'src/components/table/table';
import useTable from 'src/hooks/use_table';
import i18n from 'src/locales/i18n';
import { chooseLan } from 'src/utils/language_code';
import { print } from 'src/utils/log';
import { mapLocationToCourierModal } from '../location/locations/connect_location_to_courier_modal';
import { MenuHorizontalIcon } from '@shopify/polaris-icons';
import {
  couriersExport,
  createTrackingNumber,
  deleteTrackingNumber,
  importTrackingNumber,
} from 'src/api/courier_api';
import { fDate, fStringToStringDate } from 'src/utils/format_time';

const TYPE = {
  user: 'user',
  courier: 'courier',
};

const headers = (type) => {
  var name = { title: i18n.t('common:field.name') };
  var role = { title: i18n.t('common:field.role') };
  var country = { title: i18n.t('common:field.country') };
  var email = { title: i18n.t('common:field.email') };
  var typeAPI = { title: i18n.t('common:field.api_type') };
  if (type === TYPE.user) return [name, role, country, email];
  if (type === TYPE.courier) return [name, typeAPI, { title: '' }];
  return [];
};

const bulkAction = (data, selectedIds, handleSelectionChange, locationId) => {
  return [
    {
      content: i18n.t('common:disconnect'),
      destructive: true,
      onAction: async () => {
        const confirmation = await disconnectConfirm();
        if (confirmation) {
          var res = await deleteUserLocation(selectedIds, locationId);
          if (res.isSuccess) {
          } else {
            toast.error(res.errorMessages[0]);
          }
        }
      },
    },
  ];
};

const rowMarkup = ({ data, selectedItems }, type, locationId, tableRef) => {
  return data.map((d, i) => {
    return (
      <Row
        key={i}
        data={d}
        index={i}
        selectedItems={selectedItems}
        type={type}
        locationId={locationId}
        tableRef={tableRef}
      />
    );
  });
};

const Row = ({ data, selectedItems, type, locationId, index, tableRef }) => {
  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = useCallback(
    async () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );
  var {
    userId,
    userNameLocal,
    userNameEnglish,
    countryCode,
    loginId,
    roleNameLocal,
    roleNameEnglish,
    courierId,
    representativeImageUrl,
    courierNameLocal,
    courierNameEnglish,
    isMapping,
    trackingNumberType,
  } = data;

  var id = type === TYPE.user ? userId : courierId;
  var name =
    type === TYPE.user
      ? chooseLan(userNameLocal, userNameEnglish)
      : chooseLan(courierNameLocal, courierNameEnglish);
  var width = type === TYPE.user ? '25%' : '50%';
  return (
    <IndexTable.Row
      id={id}
      key={id}
      selected={selectedItems.includes(id)}
      position={index}
      onClick={() => {}}
    >
      {type === TYPE.user && (
        <>
          <Box
            component={IndexTable.Cell}
            sx={{ width: width }}
          >
            {name}
          </Box>
          <Box
            component={IndexTable.Cell}
            sx={{ width: width }}
          >
            {chooseLan(roleNameLocal, roleNameEnglish)}
          </Box>
          <Box
            component={IndexTable.Cell}
            sx={{ width: width }}
          >
            {countryCode}
          </Box>
          <Box
            component={IndexTable.Cell}
            sx={{ width: width }}
          >
            {loginId}
          </Box>
        </>
      )}
      {type === TYPE.courier && (
        <>
          <Box
            component={IndexTable.Cell}
            sx={{ width: width, display: 'flex', alignItems: 'center' }}
          >
            <Box sx={{ pr: 1.5 }}>
              <Image
                src={representativeImageUrl}
                sx={{
                  width: 40,
                  height: 40,
                  borderRadius: 'var(--p-border-radius-200)',
                }}
              />
            </Box>

            <div
              className="black-button"
              onClick={async () => {
                await mapLocationToCourierModal({ selectedLocation: locationId, courierId });
              }}
            >
              <Typography variant="body_md_medium">{name}</Typography>
            </div>
          </Box>
          <Box component={IndexTable.Cell}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent={'space-between'}
            >
              <Typography variant="body_md_medium">{trackingNumberType}</Typography>
              {trackingNumberType === 'Range' && (
                <Popover
                  active={popoverActive}
                  activator={
                    <Button
                      icon={MenuHorizontalIcon}
                      accessibilityLabel="Add theme"
                      variant="tertiary"
                      onClick={togglePopoverActive}
                    />
                  }
                  autofocusTarget="first-node"
                  onClose={togglePopoverActive}
                  zIndexOverride={1400}
                >
                  <ActionList
                    items={[
                      {
                        content: i18n.t('settings:mapping.create_tracking_number'),
                        onAction: async () => {
                          await togglePopoverActive();
                          const confirmed = await trackingConfirm();
                          if (confirmed.confirmed) {
                            try {
                              const result = await createTrackingNumber({
                                locationId,
                                courierId,
                                startNumber: confirmed.fromValue,
                                endNumber: confirmed.toValue,
                              });
                              if (result.isSuccess) {
                                toast.success('settings:mapping.successful_tracking_number');
                              } else {
                                toast.error(result.errorMessages[0]);
                              }
                            } catch (err) {
                              toast.error(err.toString());
                            }
                          }
                        },
                      },
                      {
                        content: i18n.t('settings:mapping.import'),
                        onAction: async () => {
                          await togglePopoverActive();
                          await importDialogCourier({
                            name: i18n.t('settings:mapping.tracking_number').toLowerCase(),
                            type: 'trackingnumber',
                            importData: importTrackingNumber,
                            dataKey: 'trackingnumber',
                            locationId,
                            courierId,
                          });
                        },
                      },
                      {
                        content: i18n.t('settings:mapping.export'),
                        onAction: async () => {
                          await togglePopoverActive();
                          await tableRef.current.exportData(id);
                        },
                      },
                      {
                        content: i18n.t('settings:mapping.delete_tracking_number'),
                        destructive: true,
                        onAction: async () => {
                          await togglePopoverActive();
                          const confirmed = await deleteConfirm();
                          if (confirmed) {
                            try {
                              const result = await deleteTrackingNumber(locationId, courierId);
                              if (result.isSuccess) {
                                toast.success('settings:mapping.successful_delete_tracking_number');
                              } else {
                                toast.error(result.errorMessages[0]);
                              }
                            } catch (err) {
                              toast.error(err.toString());
                            }
                          }
                        },
                      },
                    ]}
                  />
                </Popover>
              )}
            </Box>
          </Box>

          <Box
            component={IndexTable.Cell}
            sx={{ width: width }}
          >
            <Button
              variant="secondary"
              size="medium"
              tone={isMapping ? 'critical' : ''}
              onClick={async () => {
                if (!isMapping) {
                  try {
                    await mapLocationToCourierModal({ selectedLocation: locationId, courierId });
                  } catch (error) {
                    toast.error(error);
                  }
                } else {
                  try {
                    const response = await deleteCourierFromLocation(locationId, courierId);
                    if (response.isSuccess) {
                    } else {
                      throw Error(response.errorMessages[0]);
                    }
                  } catch (error) {
                    toast.error(error);
                  }
                }
              }}
            >
              {!isMapping ? i18n.t('common:connect') : i18n.t('common:disconnect')}
            </Button>
          </Box>
        </>
      )}
    </IndexTable.Row>
  );
};

const tabs = (count) => {
  var user = {
    label: i18n.t('common:field.user'),
    value: TYPE.user,
    onClick: () => {},
    badge: <Badge>{count.user}</Badge>,
  };
  var courier = {
    label: i18n.t('common:field.courier'),
    value: TYPE.courier,
    onClick: () => {},
    badge: <Badge>{count.courier}</Badge>,
  };
  return [user, courier];
};

const MappingTableView = ({ selectedLocation }) => {
  const tableRef = useRef();

  const { courierList, userList, searchCount, searchLoading } =
    useGetLocationMapping(selectedLocation);
  const [type, setType] = useState(TYPE.user);

  const getData = () => {
    if (type === TYPE.user) return userList;
    if (type === TYPE.courier) return courierList;
    return [];
  };

  const table = useTable({
    data: getData() ?? [],
    headers: headers(type),
    tabItems: tabs(searchCount ?? {}),
    defaultTab: type,
    rowMarkup: (props) => rowMarkup(props, type, selectedLocation, tableRef),
    showRowsPerPage: false,
    showPagination: false,
    showSearchFilter: false,
    bulkAction: (data, selectedIds, handleSelectionChange) =>
      bulkAction(data, selectedIds, handleSelectionChange, selectedLocation),
    selectable: type === TYPE.user ? true : false,
    resourceIdResolver: (d) => d.userId,
  });

  useImperativeHandle(tableRef, () => ({
    async exportData(selectedCourier) {
      const params = {
        locationId: selectedLocation,
        courierId: selectedCourier,
        pageNumber: 1,
        pageSize: 20,
      };
      const currentPageData = await couriersExport(params);
      await exportConfirm({
        pluralName: i18n.t('settings:mapping.tracking_number_range').toLowerCase(),
        singularName: i18n.t('settings:mapping.tracking_number_ranges').toLowerCase(),
        exportData: couriersExport,
        params: params,
        data: currentPageData,
        multipleItem: true,
        exportSelect: false,
        currentPage: false,
        excelData: (data) => {
          const rows =
            {
              'Courier Id': data?.courierId ?? '',
              'Tracking Number': data?.trackingNumber ?? '',
              'Is in use': data?.isUse ?? 'false',
              'Is Active': data?.isActive ?? 'false',
              'Created Date': data?.createdDate
                ? fStringToStringDate(data?.createdDate)
                  ? fStringToStringDate(data?.createdDate)
                  : fDate(data?.createdDate)
                    ? fDate(data?.createdDate)
                    : ''
                : '',
            } ?? [];
          return rows;
        },
      });
    },
  }));

  if (table.isRefetch.value) {
    setType(table.tabItems[table.tabIndex].value);
    table.onRefetched();
  }

  print('users location table load');

  return (
    <Table
      useTable={table}
      isLoading={searchLoading}
    />
  );
};

export default MappingTableView;
