import { confirmable } from 'react-confirm';
import StackedModal from './stacked_modal';
import { useTranslation } from 'react-i18next';
import { Button, DropZone, Icon, IndexTable } from '@shopify/polaris';
import { Alert, Box, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useGetMe } from 'src/api/user_api';

import FormProvider from '../hook_form/form_provider';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { isEmpty } from 'src/utils/type_check';
import FixedTable from '../table/fixed_table';
import useTable from 'src/hooks/use_table';
import { AlertTriangleIcon } from '@shopify/polaris-icons';
import { getExcelTemplate } from 'src/api/excel_api';
import { LogInAlert } from '../alert/alert';

function ImportDialogCourier({
  show,
  proceed,
  name,
  type,
  importData,
  dataKey,
  locationId,
  courierId,
}) {
  const { t } = useTranslation(['excel']);
  const [file, setFile] = useState(null);
  const { customerId, isAdmin } = useGetMe();
  const [warningMessage, setWarningMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorData, setErrorData] = useState([]);
  const [page, setPage] = useState(1);

  const schema = Yup.object().shape({
    customerId: Yup.string(),
  });

  const defaultValues = {
    customerId: customerId,
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    setWarningMessage('');
    try {
      if (data === null) return;
      var res = await importData(locationId, courierId, file);
      if (res.isSuccess) {
        var temp = res.result
          .filter((x) => !x.isCreated)
          .map((r) => {
            return { ...r, id: r[dataKey], errorMessage: r.errorMessages[0] };
          });
        setErrorData(temp);
        var total = res.result.length;
        var fail = temp.length;
        var success = total - fail;
        if (temp.length > 0) {
          setWarningMessage(
            `${t('excel:import_error_success', {
              total: total,
              name,
              success: success,
              count: success,
            })}${t('excel:import_error_fail', {
              fail: fail,
              count: fail,
            })}`,
          );
        } else {
          setSuccessMessage(t('excel:import_success', { total: total, name }));
        }
      } else {
        throw Error();
      }
    } catch (error) {
      console.error(error);
      setWarningMessage(t('excel:generic_error'));
    }
  });

  const handleDropZoneDrop = async (_dropFiles, acceptedFiles, _rejectedFiles) => {
    setFile(acceptedFiles[0]);
  };

  const dropFile =
    file === null ? (
      <DropZone.FileUpload
        actionTitle={t('excel:add')}
        actionHint={`${t('excel:accepts')} .xlsx`}
      />
    ) : (
      <Stack
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        direction={'row'}
        px={2}
        py={4}
      >
        <Typography variant="body_md_semibold">{file?.name}</Typography>
        <Button onClick={() => setFile(null)}>{t('excel:replace')}</Button>
      </Stack>
    );

  const renderAlert = (
    <>
      {!isEmpty(warningMessage) && (
        <LogInAlert
          onClose={() => setWarningMessage('')}
          severity="error"
          sx={{ py: 0 }}
          errorMsg={warningMessage}
        />
      )}
      {!isEmpty(successMessage) && (
        <Alert
          onClose={() => setSuccessMessage('')}
          sx={{ py: 0 }}
        >
          {successMessage}
        </Alert>
      )}
    </>
  );

  const table = useTable({
    data: errorData.slice((page - 1) * 10, 10 * page),
    headers: [{ title: '' }, { title: t(`excel:${dataKey}`) }, { title: t('excel:error_cause') }],
    totalDataCount: errorData.length,
    showPagination: errorData.length > 10,
    showRowsPerPage: false,
    rowsPerPageList: [10],
    rowMarkup: ({ data }) => {
      return data.map(({ id, errorMessage }, index) => (
        <IndexTable.Row
          id={index}
          key={index}
          position={index}
          onClick={() => {}}
        >
          <Box
            component={IndexTable.Cell}
            sx={{ width: '40px' }}
          >
            <Icon
              source={AlertTriangleIcon}
              tone="critical"
            />
          </Box>
          <Box
            component={IndexTable.Cell}
            sx={{ width: '45%' }}
          >
            {id}
          </Box>
          <Box
            component={IndexTable.Cell}
            sx={{ width: '45%' }}
          >
            {errorMessage}
          </Box>
        </IndexTable.Row>
      ));
    },
  });

  if (table.isRefetch.value) {
    setPage(table.page);
    table.onRefetched();
  }

  const renderErrorTable = <FixedTable useTable={table} />;

  const content = (
    <FormProvider
      methods={methods}
      onSubmit={onSubmit}
    >
      <Stack spacing={1.5}>
        {renderAlert}
        <DropZone
          onDrop={handleDropZoneDrop}
          allowMultiple={false}
          variableHeight
          accept=".xlsx"
        >
          {dropFile}
        </DropZone>
        {warningMessage && errorData.length ? renderErrorTable : null}
      </Stack>
    </FormProvider>
  );
  return (
    <StackedModal
      isOpen={show}
      title={t('excel:import', { name: name })}
      content={content}
      primaryAction={{
        content: t('common:upload'),
        onAction: onSubmit,
        loading: isSubmitting,
        disabled: (isAdmin && !isDirty) || file === null,
        isSubmit: true,
      }}
      secondaryAction={{
        content: t('common:cancel'),
        onAction: () => {
          proceed(null);
        },
      }}
      footerLeftNode={
        <Button
          variant="tertiary"
          onClick={async () => {
            await getExcelTemplate(type);
          }}
        >
          <Typography
            variant={'body_md'}
            sx={{ color: 'link', textDecoration: 'underline' }}
          >
            {t('excel:download')}
          </Typography>
        </Button>
      }
      onClose={() => {
        proceed(null);
      }}
    />
  );
}

export default confirmable(ImportDialogCourier);
