import { ENDPOINTS } from './endpoints';
import axiosInstance, {
  configMultiPartFormData,
  fetcher,
  revalidateOnlyStaleOptions,
} from 'src/utils/axios';
import useSWR, { mutate } from 'swr';
import { useMemo } from 'react';
import { getURL } from 'src/utils/url';
import { isEmpty } from 'src/utils/type_check';

var URL = null;

export function useGetCouriers(locationId) {
  const URL = ENDPOINTS.courier.get(locationId);
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);

  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );
  return memoizedValue;
}

export function useGetServiceCouriers(params) {
  if (!isEmpty(params?.locationId)) {
    URL = getURL(ENDPOINTS.courier.get_couriers(params.locationId), params);
  }
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);

  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );
  return memoizedValue;
}

export async function couriersExport(params) {
  var URL = '';
  if (!isEmpty(params?.locationId)) {
    URL = getURL(
      ENDPOINTS.courier.get_couriers_export(params.locationId, params.courierId),
      params,
    );
  }

  const res = await axiosInstance.get(URL);
  return res.data?.result?.list || [];
}

export async function deleteTrackingNumber(locationId, courierId) {
  var ret = await axiosInstance.delete(
    ENDPOINTS.courier.delete_tracking_number(locationId, courierId),
  );
  mutate(URL, (d) => d, true);
  return ret.data;
}

export async function createTrackingNumber(data) {
  var ret = await axiosInstance.post(ENDPOINTS.courier.create_tracking_number, data);
  mutate(URL, (d) => d, true);
  return ret.data;
}

export async function importTrackingNumber(locationId, courierId, excelFile) {
  const formData = new FormData();
  formData.append('excelFile', excelFile);
  formData.append('locationId', locationId);
  formData.append('courierId', courierId);
  var ret = await axiosInstance.post(
    ENDPOINTS.courier.import_tracking_number,
    formData,
    configMultiPartFormData,
  );
  mutate(URL, (d) => d, true);
  return ret.data;
}
